<template>
  <div class="margin">
    <b-row>
      <b-col sm="6">
        <i class="fas fa-money-bill-alt negative"></i>
        <Select title="Conta Origem" field="bankAccountFrom" :formName="formName" :required="true"
          v-model="transferAccount.bankAccountFrom" url="api/v1/finance/bank-account/select-all"
          :showNewRegister="false">
        </Select>

      </b-col>
      <b-col sm="6">
        <i class="fas fa-money-bill-alt positive"></i>
        <Select title="Conta Destino" field="bankAccountTo" :formName="formName" :required="true"
          v-model="transferAccount.bankAccountTo" url="api/v1/finance/bank-account/select-all" :showNewRegister="false">
        </Select>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6">
        <InputText title="Descrição" field="description" :formName="formName" :required="true" :maxLength="100"
          v-model="transferAccount.description" />
      </b-col>
      <b-col sm="3">
        <DateTime title="Data" field="date" format="DD/MM/YYYY" type="date" placeholder :formName="formName"
          :required="true" v-model="transferAccount.date" />
      </b-col>
      <b-col sm="3">
        <InputDecimal title="Valor" field="unitValue" :formName="formName" :required="true" :markFormDirty="false"
          :maxLength="5" :precision="2" type="float" v-model="transferAccount.value" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-center">
          <Button _key="btnSaveTransfer" type="primary" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fa-regular fa-right-left" size="medium" :clicked="saveTransfer" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import TransferAccount from "@/components/modules/finance/transfer-account/TransferAccount.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "TransferAccountCreateUpdate",
  components: {
    Molded,
    InputText,
    InputDecimal,
    Select,
    Button,
    DateTime,
  },
  data() {
    return {
      id: this.$route.params.id,
      formName: "transferAccountCreateUpdate",
      transferAccount: new TransferAccount(),
      urlCreate: "/api/v1/finance/transfer-account/create",
      urlUpdate: "/api/v1/finance/transfer-account/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", ["hideModal", "removeLoading"]),
    ...mapMutations("validation", ["resetValidation", "removeFormDirty"]),
    saveTransfer() {
      if (this.transferAccount.id) {
        let params = { url: this.urlUpdate, obj: this.transferAccount };
        this.putApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.removeLoading(["btnSaveTransfer"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.transferAccount };
        this.postApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.removeLoading(["btnSaveTransfer"]);
        });
      }
    },
    update(data) {
      this.transferAccount = new TransferAccount();
      this.transferAccount.update(data);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "btnTransferCreate") {
          this.resetValidation(this.formName);
          this.transferAccount = new TransferAccount();
        }
        if (event.name == "transferUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.margin {
  margin-right: 10px;
}

.positive {
  color: darkblue;
}

.negative {
  color: red;
}
</style>
