export default class TransferAccount {
    constructor() {
        this.id = "";
        this.date = "";
        this.bankAccountFrom = { id: "", content: "" };
        this.bankAccountTo = { id: "", content: "" };
        this.description = "";
        this.value = 0;
    }
    update(data) {
        this.id = data.id;
        this.date = data.date;
        this.bankAccountFrom = { id: data.bankAccountFrom.id, content: data.bankAccountFrom.content };
        this.bankAccountTo = { id: data.bankAccountTo.id, content: data.bankAccountTo.content };
        this.description = data.description;
        this.value = data.value;
    }
}