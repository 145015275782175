<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList">
      <div slot="content-buttons">
        <Button _key="btnTransferCreate" title="Transferência" classIcon="fas fa-plus-circle" type="primary"
          size="small" eventName="transferCreate" :clicked="create" />
      </div>
    </ViewTemplateWithTable>
    <Modal title="Transferência" :width="900" v-show="showModal('transferCreateUpdate')">
      <TransferAccountCreateUpdate />
    </Modal>
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";

import TransferAccountCreateUpdate from '../../../components/modules/finance/transfer-account/TransferAccountCreateUpdate.vue'

import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "TransferAccountView",
  components: {
    ViewTemplateWithTable,
    Button,
    Modal, TransferAccountCreateUpdate
  },
  data() {
    return {
      id: "",
      panel: {
        module: "Financeiro",
        title: "Transferências entre Contas",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/finance/transfer-account/get-all",
        urlGenerateAllApi: "/api/v1/billing/invoice/generate",
        urlDeleteAllApi: "/api/v1/finance/transfer-account/delete",
        showChecks: true,
        headerTable: [
          {
            title: "Data",
            field: "date",
            type: "text",
            iconSearch: false,
          },
          {
            field: "bankAccountUrlFrom",
            container: "nixloc-bank-logo",
            type: "image",
            classCssBody: "img-bank",
          },
          {
            field: "bankAccountNameFrom",
            title: "Conta Origem",
            type: "link",
            eventName: "transferUpdate",
          },
          {
            field: "bankAccountUrlTo",
            container: "nixloc-bank-logo",
            type: "image",
            classCssBody: "img-bank",
          },
          {
            field: "bankAccountNameTo",
            title: "Conta Destino",
            type: "link",
            eventName: "transferUpdate",
          },
          {
            title: "Descrição",
            field: "description",
            type: "text",
            iconSearch: true,
          },
          {
            title: "Valor",
            field: "value",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
          {
            type: "button",
            field: "statusName",
            ifFieldEqual: "Gerada",
            typeButton: "default",
            button: {
              classIcon: "fa-regular fa-ban",
              type: "danger",
              size: "small",
              eventName: "cancelInvoice",
            },
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "addEvent", "removeLoading"]),
    ...mapActions("generic", ["deleteAllApi"]),
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "transferCreate") {
          this.openModal("transferCreateUpdate");
          this.addEvent({ name: "btnTransferCreate" });
          this.removeLoading(["btnTransferCreate"]);
        }

        if (event.name == "transferUpdate") this.openModal("transferCreateUpdate");
      },
      deep: true,
    },
  },
};
</script>
